import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { VideoImgix } from '@saatva-bits/pattern-library.components.video-imgix'
const IMGIX_VIDEO_URL = process.env.NEXT_PUBLIC_IMGIX_VIDEO_URL
import { Modal } from '@saatva-bits/pattern-library.components.modal'
import styles from './FiveZoneLatex.module.scss'

const FiveZoneLatex = ({ includeVideoModal = false }) => {
    const [isPlaying, setIsPlaying] = useState() // VideoImgix relies on undefined for default value to control playback in parent
    const [isModalVisible, setIsModalVisible] = useState(false)

    const handleModalOpen = () => {
        setIsModalVisible(true)
        if (isPlaying || typeof isPlaying === 'undefined') { // handles default state of undefined plus expected boolean
            setIsPlaying(false) // don't toggle if already paused, else video will play in background when modal opens
        }
    }

    const handleModalClose = () => {
        setIsModalVisible(false)
        if (!isPlaying) {
            setIsPlaying(true) // don't toggle if already playing, else video will pause as modal opens
        }
    }

    // The Zenhaven PDP has extra video content specific to only that product
    const videoModalContent = (
        <>
            <button
                className={styles.subheading}
                onClick={handleModalOpen}
            >
                <SvgSprite className={styles.iconSubheading} spriteID='icon-function-fill-play' />
                Why natural latex?
            </button>
            <Modal
                type='popup'
                isModalVisible={isModalVisible}
                onModalClose={handleModalClose}
                className='fiveZone__modal'
                contentWrapperClassName='fiveZone__modal__content'
            >
                <VideoImgix
                    aspectRatio='16-9'
                    className={`${styles.video} fiveZone__video--modal`}
                    extension='mp4'
                    filename='why-natural-latex'
                    folder='zenhaven'
                    imgixOptions={{
                        fm: 'mp4'
                    }}
                    imgixSubdomain={IMGIX_VIDEO_URL}
                    type='video/mp4'
                    showComponentControls={false}
                    videoProps={{
                        autoPlay: true,
                        playsInline: true,
                        controls: true,
                        preload: 'auto', // needs to be auto for Safari
                        width: 1280,
                        height: 720
                    }}
                />
            </Modal>
        </>
    )

    return (
        <section className={`${styles.section} section`} id="five-zone">
            <div className="container">
                <h3 className={styles.header}>Zoned for cooler, more comfortable sleep</h3>
                <p className={`t-body ${styles.text}`}>Our exclusive <span className={styles.highlighted}>5-zone latex layer</span> goes beyond the buoyant, durable support of natural latex.</p>
                <div className={`${styles.iconsWrapper} row u-marginVertical--xl`}>
                    <div className="col--xs-5 col--xl-4 u-paddingHorizontal--xs">
                        <SvgSprite className={styles.icon} spriteID='icon-latex' />
                        <p className={styles.text}>Exclusive <span className={styles.highlighted}>vented airflow channels allow better circulation</span> throughout for superb breathability.</p>
                    </div>
                    <div className="col--xs-5 col--xl-4 u-paddingHorizontal--xs">
                        <SvgSprite className={styles.icon} spriteID='icon-zone' />
                        <p className={styles.text}>Five ergonomic zones give you <span className={styles.highlighted}>enhanced support and pressure relief</span> where you need them most.</p>
                    </div>
                </div>
                <div className="container u-padding--xs">
                    {includeVideoModal && videoModalContent}
                    <VideoImgix
                        aspectRatio='16-9'
                        className={styles.video}
                        extension='mp4'
                        filename='five-zone'
                        folder='generic'
                        imgixOptions={{
                            fm: 'mp4'
                        }}
                        imgixSubdomain={IMGIX_VIDEO_URL}
                        lazyLoadOffset={200}
                        lazyLoadContainerClassName='fiveZone__videoLazyLoadContainer'
                        parentPlayState={isPlaying} // used for pausing when Zenhaven modal is open
                        type='video/mp4'
                        videoProps={{
                            autoPlay: true,
                            muted: true,
                            loop: true,
                            playsInline: true,
                            preload: 'auto', // needs to be auto for Safari
                            width: 1280,
                            height: 720
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

FiveZoneLatex.propTypes = {
    includeVideoModal: PropTypes.bool
}

export default FiveZoneLatex
